/*
 * Copyright The NOMAD Authors.
 *
 * This file is part of NOMAD. See https://nomad-lab.eu for further info.
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import { FilterSubMenu, filterMenuContext } from './FilterMenu'
import InputRange from '../input/InputRange'
import InputField from '../input/InputField'
import InputSection from '../input/InputSection'
import { InputGrid, InputGridItem } from '../input/InputGrid'

const FilterSubMenuMechanical = React.memo(({
  id,
  ...rest
}) => {
  const {selected, open} = useContext(filterMenuContext)
  const visible = open && id === selected

  return <FilterSubMenu id={id} {...rest}>
    <InputGrid>
      <InputGridItem xs={12}>
        <InputField
          quantity="mechanical_properties"
          visible={visible}
          disableSearch
        />
      </InputGridItem>
      <InputGridItem xs={12}>
        <InputSection
          section="results.properties.mechanical.bulk_modulus"
          visible={visible}
        >
          <InputField
            quantity="results.properties.mechanical.bulk_modulus.type"
            visible={visible}
            initialSize={5}
          />
          <InputRange
            quantity="results.properties.mechanical.bulk_modulus.value"
            visible={visible}
          />
        </InputSection>
      </InputGridItem>
      <InputGridItem xs={12}>
        <InputSection
          section="results.properties.mechanical.shear_modulus"
          visible={visible}
        >
          <InputField
            quantity="results.properties.mechanical.shear_modulus.type"
            visible={visible}
            disableSearch
          />
          <InputRange
            quantity="results.properties.mechanical.shear_modulus.value"
            visible={visible}
          />
        </InputSection>
      </InputGridItem>
      <InputGridItem xs={12}>
        <InputSection
          section="results.properties.mechanical.energy_volume_curve"
          visible={visible}
        >
          <InputField
            quantity="results.properties.mechanical.energy_volume_curve.type"
            visible={visible}
            initialSize={5}
          />
        </InputSection>
      </InputGridItem>
    </InputGrid>
  </FilterSubMenu>
})
FilterSubMenuMechanical.propTypes = {
  id: PropTypes.string
}

export default FilterSubMenuMechanical
